import React, { useEffect } from 'react'

//
import { Loader } from '../storybook'
import { getUrlParameter } from '../utils/common'

const PROFILE_API = process.env.GATSBY_LINKEDIN_PROFILE_API

const CallbackPage = ({ location }) => {
  const code = getUrlParameter(location?.href, 'code')
  const state = getUrlParameter(location?.href, 'state')

  useEffect(() => {
    const body = JSON.stringify({ code, state })
    const fetchParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    }

    const fetchData = async () => {
      const result = await fetch(PROFILE_API, fetchParams).then((res) => {
        return res.json()
      })

      window.opener.postMessage({ type: 'profile', profile: result })
      window.close()
    }

    fetchData()
  }, [code, state])

  return (
    <section className="l-section">
      <div className="l-container:12/12 l-container--default-spacing">
        <Loader />
      </div>
    </section>
  )
}

export default CallbackPage
